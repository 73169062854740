import style from "./index.module.less";
import Image from "next/image";
import { useTranslation } from "next-i18next";
const Index = () => {
  const { t } = useTranslation("common");
  const dataList = [
    {
      img: "/images/home/creator/c-1.webp",
      title: t("Unlimited Removals"),
      desc: t("All leaks are delisted and requested for removal."),
    },
    {
      img: "/images/home/creator/c-2.webp",
      title: t("Constant Scans"),
      desc: t("Constant scans means leaks can’t spread."),
    },
    {
      img: "/images/home/creator/c-3.webp",
      title: t("Anonymity"),
      desc: t("Erasa takes extra precaution to keep your identity hidden."),
    },
    {
      img: "/images/home/creator/c-4.webp",
      title: t("Worldwide Scans"),
      desc: t("The AI targets leaks shared everywhere."),
    },
  ];
  return (
    <div className={style.container}>
      <h2>{t("CreatorFriendly")}</h2>
      <div className={style.main}>
        {dataList.map((item, key) => (
          <div key={key}>
            <Image
              src={item.img}
              height={114}
              width={114}
              alt={item.title}
            ></Image>
            <h3>{item.title}</h3>
            <div>{item.desc}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Index;
