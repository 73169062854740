import { useEffect, useRef } from "react";
import styles from "./index.module.less";
import { useTranslation } from "next-i18next";

const Index = () => {
  const { t } = useTranslation("common");
  return (
    <div className={styles.container}>
      <h2>
        <span>{t("The")}</span>
        <span>{t("AI-powered")}</span>
        <div>{t("Leak Detection & Removal")}</div>
      </h2>

      <div className={styles.main}>
        <video autoPlay loop muted playsInline preload="auto">
          <source
            src="https://res-front.pumpsoul.com/prod-erasa/videos/aiPower.mp4"
            type="video/mp4"
          ></source>
        </video>
      </div>
    </div>
  );
};

export default Index;
