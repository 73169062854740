import { useEffect, useRef } from "react";
import styles from "./index.module.less";
import Image from "next/image";
import { useTranslation } from "next-i18next";
const Index = () => {
  const { t } = useTranslation("common");
  const containerRef = useRef(null);
  const list = [
    {
      url: "/images/home/rowScroll/1.png",
    },
    {
      url: "/images/home/rowScroll/2.png",
    },
    {
      url: "/images/home/rowScroll/3.png",
    },

    {
      url: "/images/home/rowScroll/4.png",
    },

    {
      url: "/images/home/rowScroll/5.png",
    },
    {
      url: "/images/home/rowScroll/6.png",
    },

    {
      url: "/images/home/rowScroll/7.png",
    },

    {
      url: "/images/home/rowScroll/8.png",
    },
    {
      url: "/images/home/rowScroll/9.png",
    },
    {
      url: "/images/home/rowScroll/10.png",
    },
  ];
  useEffect(() => {
    const container = containerRef.current;

    const scrollStep = 1;
    const scrollInterval = 30;

    const scroll = () => {
      container.scrollLeft += scrollStep;
      if (
        container.scrollLeft + container.clientWidth >=
        container.scrollWidth
      ) {
        container.scrollLeft = 0;
      }
    };

    const intervalId = setInterval(scroll, scrollInterval);

    // 清理定时器
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {});
  return (
    <>
      <h2 className={styles.header}>
        <span>{t("Protecting Spicy Creators and Originals Globally")}</span>
      </h2>
      <div className={styles.main} ref={containerRef}>
        {list.map((item: any, index: number) => (
          // <img src={item.url} alt="" key={index} />
          <Image
            src={item.url}
            key={index}
            alt="erasa"
            width={100}
            height={100}
          />
        ))}
      </div>
    </>
  );
};

export default Index;
