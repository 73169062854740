import React, { useEffect, useRef, useState } from "react";
import { CommonBackContent } from "../Layouts/CommonBackContent";
import { useConfigProvider } from "@/context/ConfigProvider";
import styles from "./index.module.less";
import { Button } from "antd";
import ProtextOrigin from "./protectOriginal/index";

import FreQuestion from "./freQuestions/index";
import Image from "next/image";

import AiPower from "./aiPower/index";
import AiShowGrid from "./aiShowGrid";
import CreaterFriend from "./CreaterFriend/index";
import BottomFolder from "./BottomFolder";
import AddAccountModal from "@/components/protectedAccount/AddAcountModal";
// const AddAccountModal = dynamic(()=>import('@/components/protectedAccount/AddAcountModal'))
import useScroll from "@/context/UseScrollHeight";
import { useTranslation } from "next-i18next";

const HomeContent = ({ data }: { data: any }) => {
  const { t } = useTranslation("common");
  const scrollHeight = useScroll();
  const { isMobile } = useConfigProvider();
  const [isAddAccountModal, setIsAddAccountModal] = useState(false);
  const handleGetStart = () => {
    setIsAddAccountModal(true);
  };

  const [showIndex, setShowIndex] = useState(1);
  const timerRef = useRef(null);

  // 主图
  useEffect(() => {
    timerRef.current = setInterval(() => {
      setShowIndex((prevCount) => prevCount + 1);
    }, 7000);
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  // 两个小图
  const imgList1 = useRef([
    "/images/home/rightRange/image1.webp",
    "/images/home/rightRange/image2.webp",
    "/images/home/rightRange/image3.webp",
    "/images/home/rightRange/image4.webp",
    "/images/home/rightRange/image5.webp",
  ]);
  const imgList2 = useRef([
    "/images/home/rightRange/image6.webp",
    "/images/home/rightRange/image7.webp",
    "/images/home/rightRange/image8.webp",
    "/images/home/rightRange/image9.webp",
    "/images/home/rightRange/image10.webp",
  ]);

  const [showTopIndex, setshowTopIndex] = useState(1);
  const timerTopRef = useRef(null);

  // 主图
  useEffect(() => {
    timerTopRef.current = setInterval(() => {
      setshowTopIndex((prevCount) => prevCount + 1);
    }, 3000);
    return () => {
      if (timerTopRef.current) {
        clearInterval(timerTopRef.current);
      }
    };
  }, []);

  const [showTopIndexOther, setshowTopIndexOther] = useState(1);
  const timerTopOtherRef = useRef(null);

  // 主图
  useEffect(() => {
    timerTopOtherRef.current = setInterval(() => {
      setshowTopIndexOther((prevCount) => prevCount + 1);
    }, 7000);
    return () => {
      if (timerTopOtherRef.current) {
        clearInterval(timerTopOtherRef.current);
      }
    };
  }, []);

  // 下面的一个小图
  const platInfoList = useRef([
    {
      icon: "/images/home/smallPlatforms/Onlyfans.webp",
      lightIcon: "/images/home/smallPlatforms/Onlyfans1.webp",
      darkIcon: "/images/home/smallPlatforms/Onlyfans1-1.webp",
      desc: "OnlyFans",
      bg: "linear-gradient( 270deg, #428DFF 0%, #28B9FF 100%)",
    },
    {
      icon: "/images/home/smallPlatforms/Patreon.webp",
      lightIcon: "/images/home/smallPlatforms/Patreon1.webp",
      darkIcon: "/images/home/smallPlatforms/Patreon1-1.webp",
      desc: "Patreon",
      bg: "linear-gradient( 270deg, #141313 0%, #02183A 100%)",
    },
    {
      icon: "/images/home/smallPlatforms/Fansify.webp",
      lightIcon: "/images/home/smallPlatforms/Fansify1.webp",
      darkIcon: "/images/home/smallPlatforms/Fansify1-1.webp",
      desc: "Fansify",
      bg: "linear-gradient( 270deg, #5644F3 0%, #AF7AF7 100%)",
    },
    {
      icon: "/images/home/smallPlatforms/Manyvids.webp",
      lightIcon: "/images/home/smallPlatforms/Manyvids1.webp",
      darkIcon: "/images/home/smallPlatforms/Manyvids1-1.webp",
      desc: "Manyvids",
      bg: "linear-gradient( 270deg, #EE4180 0%, #FF5A96 100%)",
    },
  ]);
  const [platNumber, setplatNumber] = useState(0);

  return (
    <div>
      <CommonBackContent style={{ paddingTop: "0px" }}>
        <div className={styles.topMianLogo}>
          <div className={styles.left}>
            <h1>
              <p>{t("AI-Driven")}</p>
              <p>{t("Content Protection")}</p>
            </h1>
            <p>{t("The Ultimate Leak Removal Solution for Creators")}</p>
            <div
              className={styles.indicator}
              onClick={() => setIsAddAccountModal(true)}
            >
              <div className={styles.l1}>
                <span style={{ color: "#478FFF" }}>
                  <span style={{ animationDelay: "0.1s" }}>2</span>
                  <span style={{ animationDelay: "0.2s" }}>1</span>
                  <span style={{ animationDelay: "0.3s" }}>0</span>
                  <span style={{ animationDelay: "0.4s" }}>0</span>
                </span>
                <span>{t("Leaks Found")}</span>
              </div>
              <div className={styles.l2}>
                <span style={{ color: "#8789FC" }}>
                  <span style={{ animationDelay: "0.4s" }}>1</span>
                  <span style={{ animationDelay: "0.5s" }}>8</span>
                  <span style={{ animationDelay: "0.6s" }}>9</span>
                  <span style={{ animationDelay: "0.7s" }}>0</span>
                </span>
                <span>{t("Google Removed")}</span>
              </div>
              <div className={styles.l3}>
                <span style={{ color: "#F84DAD" }}>
                  <span style={{ animationDelay: "0.8s" }}>1</span>
                  <span style={{ animationDelay: "0.9s" }}>6</span>
                  <span style={{ animationDelay: "1s" }}>8</span>
                  <span style={{ animationDelay: "1.1s" }}>0</span>
                </span>
                <span>{t("X(Twitter) Removed")}</span>
              </div>
            </div>
            <Button className={styles.btn} onClick={handleGetStart}>
              {t("Get Started Free")}
            </Button>
          </div>
          {!isMobile && (
            <div className={styles.right}>
              <Image
                style={{
                  opacity: showIndex % 2 == 0 ? "1" : "0",
                  width: "100%",
                  height: "auto",
                }}
                className={styles.rightImg}
                src={"/images/home/home_right2.webp"}
                width={451}
                height={517}
                alt="erasa"
                placeholder="empty"
                priority={true}
              ></Image>
              <Image
                style={{
                  opacity: showIndex % 2 == 1 ? "1" : "0",
                  width: "100%",
                  height: "auto",
                }}
                className={styles.rightImg}
                src={"/images/home/home_right1.webp"}
                width={451}
                height={517}
                alt="erasa"
                placeholder="empty"
                priority={true}
              ></Image>
              <div className={styles.floatRightTopOne}>
                {imgList1.current.map((item, index) => (
                  <img
                    src={item}
                    alt="erasa"
                    key={index}
                    style={{
                      opacity: showTopIndex % 5 == index ? "1" : "0",
                    }}
                  />
                ))}
              </div>
              <div className={styles.floatRightTopTwo}>
                {imgList2.current.map((item, index) => (
                  <img
                    src={item}
                    alt="erasa"
                    key={index}
                    style={{
                      opacity: showTopIndexOther % 5 == index ? "1" : "0",
                      transitionDelay: "3s",
                    }}
                  />
                ))}
              </div>
              <div
                className={styles.platforms}
                style={{ backgroundImage: platInfoList.current[platNumber].bg }}
              >
                <div>
                  <img
                    src={platInfoList.current[platNumber].icon}
                    alt="erasa"
                  />
                </div>
                <div>
                  <div>
                    <span>{platInfoList.current[platNumber].desc}</span>
                  </div>
                  <div>
                    {platInfoList.current.map((item, index) =>
                      platNumber == index ? (
                        <img
                          key={index}
                          alt={item.desc}
                          src={item.lightIcon}
                          onMouseEnter={() => setplatNumber(index)}
                        ></img>
                      ) : (
                        <img
                          key={index}
                          alt={item.desc}
                          src={item.darkIcon}
                          onMouseEnter={() => setplatNumber(index)}
                        ></img>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </CommonBackContent>
      <ProtextOrigin></ProtextOrigin>
      <AiPower></AiPower>
      <AiShowGrid></AiShowGrid>
      <CreaterFriend></CreaterFriend>
      <CommonBackContent style={{ padding: "1rem" }}>
        <FreQuestion></FreQuestion>
      </CommonBackContent>
      <BottomFolder onOpenModal={handleGetStart}></BottomFolder>
      <AddAccountModal
        isModalOpen={isAddAccountModal}
        handleCancel={() => setIsAddAccountModal(false)}
        isHome={true}
      ></AddAccountModal>
      {scrollHeight > 500 && isMobile ? (
        <div className={styles.bottomBtn}>
          <Button onClick={handleGetStart}>{t("Get Started Free")}</Button>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default HomeContent;
