import React from "react";
import style from "./index.module.less";
import { Button } from "antd";
import { useTranslation } from "next-i18next";
const Index: React.FC<any> = ({ onOpenModal }) => {
  const { t } = useTranslation("common");
  return (
    <div className={style.container}>
      <div className={style.main}>
        <div className={style.contentMain}>
          <div>
            {t("Start Removing Leaks with")} <span>Erasa</span>
          </div>
          <div>
            {t(
              "leak will never stop ！Don't let leaks damage your hard work. With Erasa, you can effortlessly remove leaked content and regain control."
            )}
          </div>
          <Button className={style.btn} onClick={onOpenModal}>
            {t("Get Started")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Index;
